import React, { PropsWithChildren } from 'react'

import { useCountdown } from '../../hooks/useCountdown'
import { TIME_SEPARATOR } from '../../constants/ui'

type Props = {
  countdownUntilInMillis: number
  Wrapper: React.FC<PropsWithChildren>
}

const CountdownWrapper: React.FC<Props> = ({
  countdownUntilInMillis,
  Wrapper,
}) => {
  const { hours, minutes, seconds } = useCountdown(countdownUntilInMillis)

  return (
    <Wrapper>
      {hours}
      {TIME_SEPARATOR}
      {minutes}
      {TIME_SEPARATOR}
      {seconds}
    </Wrapper>
  )
}

export default CountdownWrapper
