import { useEffect, useState } from 'react'

type UseCountdown = {
  hours: string
  minutes: string
  seconds: string
}

const checkIfLessThanZero = (n: number): string => String(n > 0 ? n : 0).padStart(2, '0')

const useCountdown = (targetDateInMillis: number): UseCountdown => {
  const countDownDate = new Date(targetDateInMillis).getTime()

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [countDownDate])

  return getReturnValues(countDown)
}

const getReturnValues = (countDown: number): UseCountdown => {
  // calculate time left
  const hours = checkIfLessThanZero(Math.floor((countDown % (1000 * 60 * 60 * 24 * 24)) / (1000 * 60 * 60)))
  const minutes = checkIfLessThanZero(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)))
  const seconds = checkIfLessThanZero(Math.floor((countDown % (1000 * 60)) / 1000))

  return {
    hours,
    minutes,
    seconds,
  }
}

export { useCountdown }
