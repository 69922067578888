import { LinkAsButton } from '@components/UI/Button'
import styled from '@mui/material/styles/styled'

export const WrapperPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Wrapper',
  shouldForwardProp: prop => prop !== 'promoBarBackground',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  backgroundColor: theme.palette.background[9],
  color: theme.palette.neutral.main,
  padding: theme.spacing(4, 0),
}))

export const ContentPromotionBar = styled('div', {
  name: 'PromotionBar',
  slot: 'Content',
})(({ theme }) => ({
  height: 'inherit',
  width: 'inherit',
  display: 'flex',
  padding: theme.spacing(0, 4),
}))

export const ContentSlide = styled('div', {
  name: 'PromotionBar',
  slot: 'ContentSlide',
})(({ theme }) => ({
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  height: 'inherit',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(7.5),
}))

export const StyledCountdownContainer = styled('div', {
  name: 'PromotionBar',
  slot: 'CountdownContainer',
})(() => ({
  fontSize: 12,
  fontWeight: 600,
  textAlign: 'center',
  margin: 0,
}))

export const StyledDiscountLabel = styled('span', {
  name: 'PromotionBar',
  slot: 'DiscountLabel',
})(() => ({
  fontWeight: 600,
}))

export const StyledPromotionBarButton = styled(LinkAsButton, {
  name: 'PromotionBarButton',
})(() => ({
  height: '28px',
}))
