import { VerticalCarousel } from '@components/common/components/VerticalCarousel'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { isLXTeaser } from '@typesApp/teaser'
import { FC } from 'react'
import CountdownWrapper from '../../../../components/Countdown'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import {
  ContentPromotionBar,
  ContentSlide,
  StyledCountdownContainer,
  StyledPromotionBarButton,
  WrapperPromotionBar,
} from './PromotionBar.style'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { locationOriginSelector } from '@redux/selectors/site'

interface PromotionBarProps {
  data: IPlacement
}

const PromotionBar: FC<PromotionBarProps> = ({ data }) => {
  const teaserItems = data?.items.filter(isLXTeaser) || []
  const promoBarName = data?.name ?? ''

  return (
    <WrapperPromotionBar
      data-cm-metadata={`[{"_":"properties.placement-${promoBarName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${promoBarName}"}]}]`}
    >
      <ContentPromotionBar>
        {teaserItems?.length === 1 ? (
          <TextLinkPromoBar item={teaserItems[0]} />
        ) : (
          <VerticalCarousel
            delay={5000}
            autoplay
            loop={teaserItems?.length >= 2}
            slidesPerView={1}
            allowTouchMove={false}
            centeredSlides
          >
            {teaserItems.map((item, index) => (
              <TextLinkPromoBar key={index} item={item} />
            ))}
          </VerticalCarousel>
        )}
      </ContentPromotionBar>
    </WrapperPromotionBar>
  )
}

const TextLinkPromoBar: FC<{ item: ILXTeaser }> = ({ item }) => {
  const { basePath } = useStoreIdentity()
  const teaserLXCallToActionSettings = item.teaserLXCallToActionSettings[0]
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  const callToActionText = teaserLXCallToActionSettings?.callToActionText
  const formattedUrl = teaserLXCallToActionSettings?.target?.formattedUrl
  const link = `${basePath}${formattedUrl ?? ''}`

  const countdownStartInMillis = Number(item.teaserCountdownStart) * 1000
  const countdownUntilInMillis = Number(item.teaserCountdownUntil) * 1000

  const isCountDownVisible =
    item.teaserCountdownStart !== '' &&
    item.teaserCountdownUntil !== '' &&
    new Date().getTime() > countdownStartInMillis &&
    new Date().getTime() < countdownUntilInMillis

  return (
    <ContentSlide>
      {isCountDownVisible && (
        <CountdownWrapper countdownUntilInMillis={countdownUntilInMillis} Wrapper={StyledCountdownContainer} />
      )}

      <Typography
        variant="subtitle2"
        dangerouslySetInnerHTML={{
          __html: item.teaserText2,
        }}
      />

      {callToActionText && (
        <StyledPromotionBarButton
          data-element-id={`X_X_PromotionBar_${callToActionText}`}
          href={link}
          locale={locale}
          locationOrigin={locationOrigin}
          isLocaleDomain={isLocaleDomain}
        >
          {callToActionText}
        </StyledPromotionBarButton>
      )}
    </ContentSlide>
  )
}

export default PromotionBar
